import { isArray, toLower } from 'lodash';
import Dispatch from '@/class/Dispatch';
import {
  reFloatRule,
  blockSspEp,
  deleteSspEp,
  detailBlockSspEp,
  getSspEpIntegrationConfig,
  listSspEpGeoFloorRange,
  updateSspEpGeoFloorRange,
  getSspEpApproveDetail,
  updateSspUnitApprovedConfig,
  approveSspEp,
  reFloatOsRule,
  detailSspEpApprovedBudget,
  listSspEpTarget,
  approveSspEpForDspEpBudget,
  listSspEpApprovedDspGeoConfig,
  listSspEpApprovalDspGeoConfig,
} from '@/models/operation/ssp-ep/api';
import { DspOperationStateEnum } from '@/models/operation/ssp-ep/enum';
import Utils from '@/utils';
import DspMultipleApproveService from '@/pages/operation/ssp/approve/service';
import NumberFormatService from '@/services/numberformat.service';
import AdSetConstant from '@/pages/rtb/ad-set/ad-set.constant';
import BySspBudgetUnitService from '@/pages/rtb/by-ssp-unit/service';
import DspSspOperationService from '@/pages/operation/operation.service';
import OperationDspSspConstant from '@/pages/operation/operation.constant';
import UserService from '@/pages/user/user.service';
import { SspEpTrafficTypeEnum } from '@/models/ssp/enum';

class SspEpOperationService extends Dispatch {
  listSspEp(params) {
    this.dispatch({
      type: 'operationSspEp/listSspEp',
      payload: params,
    });
  }

  listOperationDspApproveUnit(params) {
    this.dispatch({
      type: 'operationSspEp/listOperationDspApproveUnit',
      payload: params,
    });
  }

  listOperationDspBlockUnit(params) {
    this.dispatch({
      type: 'operationSspEp/listOperationDspBlockUnit',
      payload: params,
    });
  }

  updateOperationState(params) {
    this.dispatch({
      type: 'operationSspEp/updateOperationState',
      payload: params,
    });
  }

  updateFilterFormDspOptions(list) {
    this.dispatch({
      type: 'operationSspEp/updateFilterFormDspOptions',
      payload: list,
    });
  }

  updateApprovedDspOptions(list) {
    this.dispatch({
      type: 'operationSspEp/updateApprovedDspOptions',
      payload: list,
    });
  }

  listDspOffer(params) {
    this.dispatch({
      type: 'operationSspEp/listDspOffer',
      payload: params,
    });
  }

  listInternalDspEp(params) {
    this.dispatch({
      type: 'operationSspEp/listInternalDspEp',
      payload: params,
    });
  }

  // 获取ep config
  static getSspEpIntegrationConfig(integrationId) {
    return getSspEpIntegrationConfig(integrationId);
  }

  static updateSspEpGeoFloorRange(data) {
    return updateSspEpGeoFloorRange(data);
  }

  static listSspEpGeoFloorRange(data) {
    return listSspEpGeoFloorRange(data);
  }

  // 审批dsp ep config
  static updateSspUnitApprovedConfig(list) {
    if (isArray(list) && list.length) {
      if (list.length > 1) {
        list.forEach((item) => updateSspUnitApprovedConfig(item));

        return;
      }

      return updateSspUnitApprovedConfig(list[0]);
    }

    return Promise.reject();
  }

  static approveSspEp(body) {
    return approveSspEp(body);
  }

  static detailSspEpApprovedBudget(data) {
    return detailSspEpApprovedBudget(data);
  }

  static listSspEpTarget(data) {
    return listSspEpTarget(data);
  }

  static approveSspEpForDspEpBudget(data) {
    return approveSspEpForDspEpBudget(data);
  }

  static blockSspEp(body) {
    return blockSspEp(body);
  }

  static deleteSspEp(body) {
    return deleteSspEp(body);
  }

  static getSspEpApproveDetail(body) {
    return getSspEpApproveDetail(body);
  }

  static async listSspEpApprovedDspGeoConfig(params) {
    const approved = await listSspEpApprovedDspGeoConfig(params);

    const matched = await listSspEpApprovalDspGeoConfig(params);

    return SspEpOperationService.mergeSspApprovedDspEpGeoConfig(approved, matched);
  }

  // 合并已审批和待审批 dsp ep os配置
  static mergeSspApprovedDspEpGeoConfig(approved, matched) {
    const generatorMatchedId = (item) => {
      const { dspIntegrationId, geo } = item;

      return [dspIntegrationId, geo].join('_');
    };

    const formatApproved = isArray(approved)
      ? approved.map((item) => ({ ...item, uniqueId: generatorMatchedId(item) }))
      : [];

    const geoList = isArray(matched)
      ? matched.map((matchedItem) => {
          const mergeMatchedId = generatorMatchedId(matchedItem);

          const flag = formatApproved.find((item) => item.uniqueId === mergeMatchedId);

          return {
            ...flag,
            ...matchedItem,
            uniqueId: mergeMatchedId,
          };
        })
      : [];

    return {
      geoList,
      geoSelectorData: geoList.map((item) => ({ id: item.uniqueId, name: item.geoName })),
      geoSelected: formatApproved.map((item) => item.uniqueId),
    };
  }

  static detailBlockSspEp(params) {
    return detailBlockSspEp(params);
  }

  static deleteOfferAdsetDspSspBudget(ids) {
    return BySspBudgetUnitService.deleteSspBudgetUnit(ids);
  }

  static reFloatRule(dspOperationId) {
    return reFloatRule(dspOperationId);
  }

  static reFloatOsRule(dspOperationId) {
    return reFloatOsRule(dspOperationId);
  }

  static getOperationParams(item) {
    if (!Utils.falsely(item)) {
      const isExternalUser = UserService.getIsExternalUser();

      const { id: sspIntegrationId, adFormat, trafficType } = item;

      // 外部客户需要限制datacenter
      return isExternalUser
        ? {
            sspIntegrationId,
            adFormat,
            trafficType,
          }
        : {
            sspIntegrationId,
            trafficType,
          };
    }

    return {
      sspIntegrationId: null,
      trafficType: SspEpTrafficTypeEnum.Mainstream,
    };
  }

  static generalDspSortList(ids) {
    if (isArray(ids)) {
      return ids.map((id) => ({
        dspIntegrationId: id,
        time: +new Date(),
      }));
    }

    return [];
  }

  static mergeConfigBlockDetail(config, detail) {
    const existConfig = config && config.blockCreativeIds?.length;

    if (existConfig && !detail) {
      const { blockCreativeIds } = config;

      return {
        ...config,
        creativeIds: blockCreativeIds,
      };
    }

    return detail || {};
  }

  static transformSspApprovedDspConfig(dspOperations) {
    if (isArray(dspOperations) && dspOperations.length) {
      return dspOperations.map((item) => {
        const {
          margin,

          qps,
          floorIncrease,
          payoutDecrease,
          fixed,
          floated,
          opened,
          qpsFloatRulesName,
          id,
          dspSspApproveId,

          dspIntegrationId,
          geo,
          geoName,
          uniqueId,
        } = item;

        const qpsFloatRule = {
          ruleId: floated,
          originRuleId: floated,
          fixed,
          qpsFloatRulesName,
          opened,
        };

        const qpsRuleType = DspSspOperationService.mergeQpsRuleType(item);

        return {
          margin,

          qps: !Utils.falsely(qps) && qps !== -1 ? qps : null,
          dspSspApproveId,
          qpsRuleType,
          id,
          floorIncrease,
          payoutDecrease,
          qpsFloatRule,
          dspIntegrationId,
          geo,
          geoName,
          uniqueId,
        };
      });
    }

    return [];
  }

  // dsp审批详情转换成表单
  static sspUnitApprovedDetailToForm(dspOperations) {
    const mergeDspOperations = SspEpOperationService.transformSspApprovedDspConfig(dspOperations);

    return {
      externalDspOperations: mergeDspOperations,
      externalDspEp: mergeDspOperations.map((item) => item.dspIntegrationId),
    };
  }

  // 预算详情转换成表单
  static sspEpApproveBudgetToForm(detail) {
    if (detail) {
      const {
        internalDspOperationSwitch,
        internalDspSspOfferAdSetBudgets,
        internalDspOperations,
        adSetBundleWhiteLists,
      } = detail;

      // 匹配的内部ep id
      const internalDspEpIds =
        isArray(internalDspOperations) && internalDspOperations.length
          ? internalDspOperations.map((item) => item.dspIntegrationId)
          : [];

      const mergeInternalDspSspOfferAdSetBudgets =
        isArray(internalDspSspOfferAdSetBudgets) && internalDspEpIds.length
          ? internalDspSspOfferAdSetBudgets
              .filter((item) => internalDspEpIds.includes(item.dspIntegrationId))
              .map((item) => {
                const {
                  offerId,
                  offerName,
                  adSetId,
                  adSetName,
                  adFormat,
                  budget,
                  payoutIncrease = 0,
                  bidRates,

                  ...rest
                } = item;

                const id = DspSspOperationService.generateOfferAdsetId(item);

                return {
                  ...rest,
                  offerId,
                  offerName,
                  adSetId,
                  adSetName,
                  adFormat,
                  budget,
                  payoutIncrease,
                  dspOfferId: id,
                  bidRates:
                    isArray(bidRates) && bidRates.length ? bidRates : AdSetConstant.defaultBidRate,
                };
              })
          : [];

      const uniqueBudgetList = Utils.uniqueArrayByKey(
        mergeInternalDspSspOfferAdSetBudgets,
        'dspOfferId',
      );

      const dspOfferId = uniqueBudgetList.map((item) => item.dspOfferId);

      return {
        dspOfferId,
        adSetBundleWhiteLists,
        internalDspOperationSwitch: internalDspOperationSwitch === DspOperationStateEnum.ON,
        internalDspSspOfferAdSetBudgets: uniqueBudgetList,
      };
    }

    return {};
  }

  static getApprovedDspOptions(list) {
    if (isArray(list)) {
      return list.map((item) => {
        const { dspIntegrationId, dspUnit } = item;

        return {
          id: dspIntegrationId,
          name: dspUnit,
        };
      });
    }

    return [];
  }

  static sortDspOperations(operations, sortList) {
    if (isArray(operations) && isArray(sortList) && sortList.length) {
      return operations
        .map((item) => {
          const sorter = sortList.find((child) => child.dspIntegrationId === item.dspIntegrationId);

          if (sorter) {
            return {
              ...item,
              updateTime: sorter.time,
            };
          }

          return item;
        })
        .sort((a, b) => {
          return b.updateTime - a.updateTime;
        });
    }

    return operations;
  }

  // 解析聚合id
  static decodeOperationId(operationId) {
    if (operationId && operationId.includes('_')) {
      const [sspIntegrationId, countryId, os, adFormat] = operationId
        .split('_')
        ?.map((item) => DspMultipleApproveService.number(item));

      return {
        sspIntegrationId,
        countryId,
        os,
        adFormat,
      };
    }

    return {
      sspIntegrationId: null,
      countryId: null,
      os: null,
      adFormat: null,
    };
  }

  // 根据offer ssp ep 过滤出 可用的内部dsp ep
  static getMatchedAndNotApprovedDspEp(
    internalDspEpList,
    internalOperationConfigList,
    ssp,
    externalSspEpTargetList,
  ) {
    // 已经审批过
    const approvedInternalDspEpIds = isArray(internalOperationConfigList)
      ? internalOperationConfigList.map((item) => item.dspIntegrationId)
      : [];

    // offer matched id
    const sspTargetMatchedIds = isArray(externalSspEpTargetList)
      ? externalSspEpTargetList.map((item) => item.matchedId)
      : [];

    // ssp ep datacenter
    const sspDataCenter =
      ssp &&
      (ssp.dataCenter ||
        DspSspOperationService.getDatacenterFromUnit(ssp.sspUnit || ssp.sspUnitName));

    /**
     *  1.如果当前ssp ep 在 operation A里面审批过内部dsp ep 需要过滤掉
     *  2.如果当前匹配的dsp是内部52 name需要保持datacenter一致
     *  3.countyId adFormat要一致
     * **/
    return internalDspEpList.filter((dsp) => {
      const notApprovedFlag = !approvedInternalDspEpIds.includes(dsp.dspIntegrationId);

      const sameDatacenterFlag =
        dsp.dspId === OperationDspSspConstant.ONLINE_DSP_SUIBIAN_ID
          ? sspDataCenter === dsp.dspEp // dspEp = dsp datacenter
          : true;

      const matched = sspTargetMatchedIds.includes(dsp.matchedId);

      return matched && notApprovedFlag && sameDatacenterFlag;
    });
  }

  static genMatchedUniqueId(item) {
    const { countryId, os, adFormat } = item;

    return `${countryId}_${os}_${adFormat}`;
  }

  // 内部被审批的dsp表单数据转换成提交数据
  static internalDspOperations2Body(
    list,
    internalDspEpList,
    sspEpTargetList,
    ssp,
    internalOperationConfigList,
    dspOffer,
  ) {
    if (isArray(list) && list.length) {
      // 选中的dsp offer ids
      const selectedOfferIds = list.map((item) => item.dspOfferId);

      // 选中的offer匹配内部ep的id
      const selectedOfferUniqueIds = dspOffer
        .filter((offer) => selectedOfferIds.includes(offer.id))
        .map((item) => SspEpOperationService.genMatchedUniqueId(item));

      // 根据选中的offer 匹配内部dsp ep
      const matchedInternalDspEpList = SspEpOperationService.getMatchedAndNotApprovedDspEp(
        internalDspEpList,
        internalOperationConfigList,
        ssp,
        sspEpTargetList,
      )
        .filter((dspEp) => {
          const dspEpUniqueId = SspEpOperationService.genMatchedUniqueId(dspEp);

          return selectedOfferUniqueIds.includes(dspEpUniqueId);
        })
        .map((dsp) => {
          return {
            ...dsp,
            margin: 30,
          };
        });

      // 避免重复的内部dsp ep
      const uniqueMatchedInternalDspEpList = Utils.uniqueArrayByKey(
        matchedInternalDspEpList,
        'dspIntegrationId',
      );

      // 预算
      const offerAdSetBudgets = [];

      uniqueMatchedInternalDspEpList.forEach((dspEp) => {
        list.forEach((item) => {
          const { dspOfferId, payoutIncrease = 0, bidRates, ...rest } = item;

          const { offerId, adSetId, adFormat, countryId, os } =
            DspSspOperationService.parseOfferAdsetId(dspOfferId);

          const { dspId, dspIntegrationId } = dspEp;

          const budget = {
            ...rest,

            dspId,
            dspIntegrationId,
            offerId,
            adSetId,
            adFormat,
            countryId,
            os,

            sspId: ssp.sspId,
            sspIntegrationId: ssp.sspIntegrationId || ssp.id,
            bidRates:
              isArray(bidRates) && bidRates.length ? bidRates : AdSetConstant.defaultBidRate,
            payoutIncrease: NumberFormatService.toFixed(payoutIncrease),
            margin: 30,
          };

          offerAdSetBudgets.push(budget);
        });
      });

      return {
        internalDspList: uniqueMatchedInternalDspEpList,
        offerAdSetBudgets,
      };
    }

    return {
      internalDspList: [],
      offerAdSetBudgets: [],
    };
  }

  // 找出被移除的预算
  static getNeedDeleteSspBudget(detailDspOperations, formDspOperations) {
    // 历史预算id
    const ids = isArray(detailDspOperations)
      ? detailDspOperations.filter((item) => !Utils.falsely(item.id)).map((item) => item.id)
      : [];

    // 表单提交还剩余的id
    const formDspOperationIds = isArray(formDspOperations)
      ? formDspOperations.filter((item) => !Utils.falsely(item.id)).map((item) => item.id)
      : [];

    if (ids.length) {
      return formDspOperationIds.length === 0
        ? ids
        : formDspOperationIds.filter((id) => !ids.includes(id));
    }

    return [];
  }

  // 跟内部dsp不匹配的offer
  static disableNotMatchedInternalDspEp(internalDspEps, dspOffers) {
    if (isArray(dspOffers) && dspOffers.length) {
      const matchedIds = isArray(internalDspEps)
        ? internalDspEps.map((item) => item.matchedId)
        : [];

      return dspOffers.map((offer) => {
        const offerMatchedId =
          offer.matchedId || SspEpOperationService.generateMatchedInternalDspKey(offer);

        const disabled = !matchedIds.includes(offerMatchedId);

        return {
          ...offer,
          disabled,
        };
      });
    }

    return [];
  }

  static generateMatchedInternalDspKey(item) {
    const { countryId, os, adFormat } = item;

    return `${countryId}_${os}_${adFormat}`;
  }

  static parseMatchedInternalDspKey(string) {
    if (string && string.includes('_')) {
      const [countryId, os, adFormat] = string.split('_');

      return {
        countryId,
        os,
        adFormat,
      };
    }

    return {
      countryId: null,
      os: null,
      adFormat: null,
    };
  }

  static checkExistedBudgetEqualsZero(list) {
    if (isArray(list)) {
      return list.some((item) => item.hasOwnProperty('budget') && item.budget === 0);
    }

    return false;
  }

  // 是否dsp ep 跟ssp是否是同一个品牌
  static transformDspEpItem(dspEpList, ssp, configList) {
    if (isArray(dspEpList) && ssp) {
      const approvedDspEpIds = isArray(configList)
        ? configList.map((item) => item.dspIntegrationId)
        : [];

      const sspBrand = DspSspOperationService.getBrandNameFromUnit(
        ssp.sspUnit || ssp.sspName || sspName.sspUnitName,
      );

      const isExternalUser = UserService.getIsExternalUser();

      return dspEpList.map((item) => {
        const dspBrand = DspSspOperationService.getBrandNameFromUnit(item.dspUnit);

        const isInternalDsp = item.dspId === OperationDspSspConstant.ONLINE_DSP_SUIBIAN_ID;

        // 同品牌禁用，自己不给批给自己
        const dspSspSameBrand = toLower(dspBrand) === toLower(sspBrand);

        // 已经审批过的dsp ep
        const isApproved = approvedDspEpIds.length
          ? approvedDspEpIds.includes(item.dspIntegrationId)
          : false;

        const reasonObj = {
          internalDsp: !isExternalUser && isInternalDsp,
          sameBrand: dspSspSameBrand,
          approvedOPA: isApproved,
        };

        const reason = DspSspOperationService.getDisableReason(reasonObj);

        return {
          ...item,
          disabled: (!isExternalUser && (dspSspSameBrand || isInternalDsp)) || isApproved,
          title: reason.join('.\n'),
        };
      });
    }

    return [];
  }

  static transformSspUnitBidFloorPrice(list) {
    if (isArray(list) && list.length) {
      return list.map((item) => {
        const { sspIntegrationId, countryId, geoName, bidPriceRangeMin, bidPriceRangeMax } = item;

        return {
          sspIntegrationId,
          countryId,
          geoName,
          bidPriceRangeMin,
          bidPriceRangeMax,
        };
      });
    }

    return [];
  }

  static floorPriceRangeByOs2Body(list) {
    if (isArray(list) && list.length) {
      return list.map((item) => {
        const { sspIntegrationId, countryId, bidPriceRangeMin, bidPriceRangeMax } = item;

        return {
          sspIntegrationId,
          countryId,
          bidPriceRangeMin,
          bidPriceRangeMax,
        };
      });
    }

    return [];
  }

  static SspEpApprovedDspGeoListToBody(list, common) {
    if (isArray(list) && list.length) {
      return list.reduce((curr, item) => {
        const { geoSelected = [], approvedDspGeo = [] } = item;

        // 过滤掉未选中的
        const mergeApprovedDspGeo =
          isArray(approvedDspGeo) && isArray(geoSelected)
            ? approvedDspGeo.filter((child) => geoSelected.includes(child.uniqueId))
            : [];

        if (mergeApprovedDspGeo.length) {
          const dspOperations =
            DspSspOperationService.approvedDspEpConfigToBody(mergeApprovedDspGeo);

          const mergeItem = {
            ...common,
            dspIntegrationId: item.dspIntegrationId,
            dspOperations,
          };

          return [...curr, mergeItem];
        }

        return curr;
      }, []);
    }

    return [];
  }

  static bidPriceRangeChanged(fields) {
    const budgetFields = ['floorPriceRangeByGeo'];

    return DspSspOperationService.fieldsChanged(fields, budgetFields);
  }

  static sspBudgetChanged(fields) {
    const budgetFields = [
      'dspOfferId',
      'internalDspSspOfferAdSetBudgets',
      'internalDspOperationSwitch',
      'bidRates',
    ];

    return DspSspOperationService.fieldsChanged(fields, budgetFields);
  }

  // 找出被移除的offer 的预算
  static getRemovedOfferBudgetIds(detailBudgets, approvedBudget, approveInternal) {
    const { internalDspOperations, internalDspOperationSwitch } = approveInternal || {};

    // 是否关闭内部审批
    const isClosed = internalDspOperationSwitch === DspOperationStateEnum.OFF;

    const genKey = (item) => {
      const { offerId, adSetId, countryId, os } = item;

      return `${offerId}_${adSetId}_${countryId}_${os}`;
    };

    if (isClosed) {
      // 关闭移除所有预算
      return detailBudgets.map((item) => item.id);
    }

    if (isArray(detailBudgets) && isArray(approvedBudget)) {
      // 选中的offer
      const approvedOfferIds = approvedBudget.map((item) => genKey(item));

      // 匹配的内部dsp ep
      const budgetMatchedInternalDspEpIds = internalDspOperations.map(
        (item) => item.dspIntegrationId,
      );

      // 需要移除的预算
      return detailBudgets
        .filter(
          (item) =>
            !approvedOfferIds.includes(genKey(item)) ||
            !budgetMatchedInternalDspEpIds.includes(item.dspIntegrationId),
        )
        .map((item) => item.id);
    }

    return [];
  }
}

export default SspEpOperationService;
