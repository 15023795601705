import { history } from 'umi';
import { message } from 'antd';
import { isArray, isFunction, omitBy, isObject, isNil, isString } from 'lodash';

class Utils {
  static getSystemEnv() {
    const { navigator, innerWidth } = window;

    const ua = navigator.userAgent;

    const isIphone = /(iPhone|iPad|iPod|iOS)/i.test(ua);
    const isAndroid = /(Android)/i.test(ua);
    const isMobile = /(Android|webOS|iPhone|iPad|iPod|BlackBerry)/i.test(ua);
    const isPc = !isMobile;

    return {
      isIE: ua.indexOf('Trident') > -1, // IE内核
      isOpera: ua.indexOf('Presto') > -1, // opera内核
      isWebKit: ua.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
      isFirefox: ua.indexOf('Gecko') > -1 && ua.indexOf('KHTML') === -1, // 火狐内核
      wechat: ua.indexOf('MicroMessenger') > -1, // 是否微信
      // @ts-ignore
      qq: ua.match(/\sQQ/i) === ' qq', // 是否QQ
      isResponsiveMobile: isPc && innerWidth < 768, // pc 浏览器模式下面 收缩浏览器宽度
      isIphone,
      isAndroid,
      isPc,
      isMobile,
    };
  }

  static falsely(value: any): boolean {
    return value === null || value === undefined || value === '' || value === false;
  }

  static getArrayIds(list: object[], field = 'id') {
    return Utils.getValuesWithProperty(list, field);
  }

  static copyByInput(value: any, next?: () => void) {
    if (!Utils.falsely(value)) {
      const input = document.createElement('input');

      input.setAttribute('readonly', 'readonly');
      input.setAttribute('value', value);
      document.body.appendChild(input);

      input.select();
      input.setSelectionRange(0, 99999);

      if (document.execCommand('copy')) {
        document.execCommand('copy');
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        isFunction(next) && next();
      }

      document.body.removeChild(input);
      message.success('Has been copied to the clipboard!', 2).then();
    }
  }

  static copy(value: any, next?: () => void) {
    try {
      navigator.clipboard.writeText(value).then(() => {
        message.success('Has been copied to the clipboard!', 2).then();
      });
    } catch (err) {
      Utils.copyByInput(value, next);
    }
  }

  // param1>param2:1 param1===param2:2 param1<param2:3
  static versionCompare(preVersion = '', lastVersion = ''): number {
    const sources = preVersion.split('.');

    const dests = lastVersion.split('.');

    const maxL = Math.max(sources.length, dests.length);

    let result = 0;

    for (let i = 0; i < maxL; i++) {
      const preValue: any = sources.length > i ? sources[i] : 0;

      const preNum = isNaN(Number(preValue)) ? preValue.charCodeAt() : Number(preValue);

      const lastValue: any = dests.length > i ? dests[i] : 0;

      const lastNum = isNaN(Number(lastValue)) ? lastValue.charCodeAt() : Number(lastValue);

      if (preNum < lastNum) {
        result = -1;

        break;
      } else if (preNum > lastNum) {
        result = 1;

        break;
      }
    }

    return result;
  }

  static downloadFile(blob: Blob, filename: string = (+new Date()).toString()) {
    const fileName = `${filename}.xlsx`;

    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    window.URL.revokeObjectURL(link.href);
  }

  static checkParams(params: object): object {
    if (params) {
      const newParams = {};

      Object.keys(params).forEach((key) => {
        const value = params[key];

        if (
          (isArray(value) && value.length) ||
          (!isArray(value) && ![null, undefined, ''].includes(value))
        ) {
          newParams[key] = value;
        }
      });

      return newParams;
    }

    return params;
  }

  static filterNullable(target: object) {
    if (isObject(target)) {
      return omitBy(target, isNil);
    }

    return target;
  }

  static blobToBase64(blob: Blob, success?: Function, fail?: Function) {
    const oFileReader = new FileReader();

    oFileReader.readAsDataURL(blob);

    oFileReader.onloadend = (e) => {
      if (success) {
        // @ts-ignore
        success(e.target.result);
      }
    };

    if (fail) {
      // @ts-ignore
      oFileReader.onerror = fail;
    }
  }

  static base64ToFile(base64: string, name?: string, type?: string) {
    if (!isString(base64)) {
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      fetch(base64)
        .then((res) => res.blob())
        .then((blob) => {
          resolve(new File([blob], name || 'creative', { type: type || 'image/png' }));
        });
    });
  }

  static fetchNetworkImageToBase64(url: string, cb?: Function) {
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        Utils.blobToBase64(blob, cb);
      });
  }

  static transformEnumForSelectList(target: any[]) {
    return Object.entries(target).map((item) => {
      const [name, id] = item;

      return {
        id,
        name,
      };
    });
  }
  static getUrlExtension(url: string) {
    const idx = url?.lastIndexOf('.');

    const fileExtension = url?.substr(idx + 1);

    return fileExtension;
  }

  static getFileExtension(file: File) {
    const { name, type } = file;

    const fileExtension = Utils.getUrlExtension(name);

    const [_, typeExtension] = type?.split('/');

    return typeExtension || fileExtension;
  }

  static getPathname(path?: string) {
    const pathname = path || history.location.pathname;

    const length = pathname?.length;

    return pathname && pathname[length - 1] === '/' ? pathname.substring(0, length - 1) : pathname;
  }

  static nullableObject(object: object) {
    if (object) {
      return Object.keys(object).every((key) => Utils.falsely(object[key]));
    }

    return true;
  }

  // [min - max]随机数
  static getRandomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  /**
   * @description:提取数组中的`property`到新的数组中
   * **/
  static getValuesWithProperty(list: any[], property: string) {
    if (isArray(list) && list.length && isString(property)) {
      return list.filter((item) => item.hasOwnProperty(property)).map((item) => item[property]);
    }

    return [];
  }

  static uniqueArrayByKey(list: any[], key: string) {
    if (isArray(list) && list.length && key) {
      return list.reduce((curr, item) => {
        const keyValue = item[key];

        const isRepeat = curr.some((child: any) => child[key] === keyValue);

        if (!isRepeat) {
          curr.push(item);
        }

        return curr;
      }, []);
    }

    return [];
  }

  static intersection(arr1: any[], arr2: any[]) {
    if (isArray(arr1) && isArray(arr2)) {
      const set = new Set(arr2);

      return arr1.filter((item) => set.has(item));
    }

    return [];
  }

  static addQueryParam(url: string, params: object) {
    // 检查URL中是否已存在查询字符串
    const hasQuery = url.indexOf('?') !== -1;

    const queryString = Object.keys(params)
      .map((key) => {
        return encodeURIComponent(key) + '=' + params[key];
      })
      .join('&');

    url += hasQuery ? '&' + queryString : '?' + queryString;

    return url;
  }

  static getDecimalPlaces(num: number | string) {
    const decimalStr = num.toString().split('.')[1] || '';

    return decimalStr.length;
  }
}

export default Utils;
