import { history } from 'umi';
import qs from 'qs';
import psl from 'psl';
import { replace, isArray, omit, last, upperFirst, get, pick, isString } from 'lodash';

import {
  listMobileDevice,
  listTerminalOs,
  uploadFile,
  listCountryRegion,
  listTrackingParam,
} from '@/models/common/api';
import { getCountrySelectList } from '@/components/BSelect/select.api';
import type { NCommon } from '@/models/common/interface';
import ValidatorService from '@/services/validator.service';

class CommonService {
  static listMobileDevice() {
    return listMobileDevice();
  }

  static listTerminalOs() {
    return listTerminalOs();
  }

  static listTrackingParam() {
    return listTrackingParam();
  }

  static getCountrySelectList() {
    return getCountrySelectList();
  }

  static validatorTextHasChinese(text: string) {
    if (text) {
      return /[\u4E00-\u9FA5]/i.test(text);
    }

    return false;
  }

  static uploadFile(file: File) {
    return uploadFile(file);
  }

  static listCountryRegion(params: NCommon.IListRegionParams) {
    return listCountryRegion(params);
  }

  static replaceOssUrl(url: string) {
    const replaceUrl = (imgUrl: string) => {
      if (imgUrl) {
        const tencentOosPath = 'https://admbucket.com';

        const tencentPathPrefix = '/cos';

        const aliyunCos = 'https://eco-system.xyz';

        const aliyunPathPrefix = '/oss';

        if (url.includes(tencentOosPath)) {
          return replace(imgUrl, tencentOosPath, tencentPathPrefix);
        }

        if (url.includes(aliyunCos)) {
          return replace(imgUrl, aliyunCos, aliyunPathPrefix);
        }

        return imgUrl;
      }

      return imgUrl;
    };

    if (url) {
      const localEnvs = ['localhost', '127.0.0.1'];

      const { origin, hostname } = window.location;

      const isLocalEnv = localEnvs.includes(hostname);

      if (isLocalEnv) {
        return url;
      }

      const relativePath = replaceUrl(url);

      return `${origin}${relativePath}`;
    }

    return url;
  }

  static getDspSspRegisterUrl() {
    return `${location.host}/register`;
  }

  static getIsRegisterPage() {
    return location.pathname.includes('/register');
  }

  /**
   * @description:umi history 解析通过qs库传递的参数
   * **/
  static getLocationSearchParams() {
    return history.location.search ? qs.parse(history.location.search.substring(1)) : {};
  }

  // window location
  static getSearchParams(field?: string) {
    let query: any = {};

    try {
      query = location.search ? qs.parse(location.search.substring(1)) : {};
    } catch (e) {}

    if (isString(field)) {
      return get(query, field);
    }

    if (isArray(field)) {
      return pick(query, field);
    }

    return query;
  }

  // 删除url中的参数，不刷新页面
  static omitUtlParams(key: string | string[]) {
    const { pathname } = window.location;

    // 需要移除的key
    const formatKeys = isArray(key) ? key : [key];

    // 移除后的参数对象
    const mergeQueryParams = omit(CommonService.getLocationSearchParams(), formatKeys);

    // 格式化
    const stringParams = qs.stringify(mergeQueryParams);

    // 需要替换的url
    const url = stringParams ? `${pathname}?${stringParams}` : pathname;

    window.history.replaceState(null, '', url);
  }

  static getCompanyFromDomain() {
    const ADMILE = 'Admile';

    let ret: string = ADMILE;

    const data = psl.parse(window.location.host);

    // @ts-ignore
    if (data && data.subdomain) {
      // @ts-ignore
      ret = (CommonService.getNameFromSubdomain(data.subdomain) as string) || ADMILE;
    }

    return upperFirst(ret);
  }

  static getNameFromSubdomain(subdomain: string) {
    if (subdomain === 'www') {
      return null;
    }

    if (subdomain && subdomain.includes('www.')) {
      return last(subdomain.split('www.'));
    }

    return subdomain;
  }

  static isLikeBundleId(bundleId: string) {
    if (bundleId) {
      const isDomain = ValidatorService.isValidDomain(bundleId);

      const likeAndroid = bundleId.includes('.');

      const likeIos = ValidatorService.isValidAppleBundleId(bundleId);

      return !isDomain && (likeAndroid || likeIos);
    }

    return false;
  }
}

export default CommonService;
