export enum SspStatus {
  Active = 'Active',

  Paused = 'Paused',

  Deleted = 'Deleted',
}

export enum PlatformEnum {
  ADX = 'ADX',

  DSP = 'DSP',
}

export enum PriceModel {
  BURL = 'BURL',

  NURL = 'NURL',

  ADM = 'ADM',
}

export enum PxTagEnum {
  ON = 1,

  OFF = 0,
}

export enum PxTagRadioEnum {
  Custom = 1,

  SystemDefault = -1,
}

export enum SspIntegrationStatus {
  Active = 'Active',

  Paused = 'Paused',
}

export enum SspEpCopyStatusEnum {
  ON = 1,

  OFF = 0,
}

export enum SspEpViewStatusEnum {
  ON = 1,

  OFF = 0,
}

export enum SspCreativeVerification {
  ON = 1,

  OFF = 0,

  NoSet = -1,
}

export enum SspEpTrafficTypeEnum {
  Mainstream = 'Mainstream',

  'Non-Mainstream' = 'Non-Mainstream',
}
