import type { Settings as LayoutSettings } from '@ant-design/pro-layout';
import type { RunTimeLayoutConfig } from 'umi';
import { history, Link } from 'umi';
import { App } from 'antd';
import { SettingDrawer } from '@ant-design/pro-layout';
import { AdminUserContext, CanCreateOperationContext, IsAdxBDContext } from '@/context';
import PageLoading from '@/components/PageLoading';
import RightContent from '@/components/RightContent';
import defaultSettings from '../config/defaultSettings';
import AuthService from '@/services/auth.service';
import InitService from '@/services/init.service';
import PermissionService from '@/services/permission.service';
import CommonService from '@/services/common.service';

const loginPath = '/user/login';

// 进入 打开新页面
const enterNewTabList = ['/report/adx', '/report/dsp'];

// 离开 打开新页面
const leaveNewTabList: string[] = [];

function initLocale(lang: string) {
  const local = localStorage.getItem('umi_locale');

  if (!local || local === 'null' || local === 'undefined') {
    localStorage.setItem('umi_locale', lang);
  }
}

initLocale('en-US');

InitService.run();

/** 获取用户信息比较慢的时候会展示一个 loading */
export const initialStateConfig = {
  loading: <PageLoading />,
  menus: [],
};

// dva config
export const dva = {
  config: {
    onError(e: any) {
      e.preventDefault();
      console.log(e.message);
    },
  },
};

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: any;
  loading?: boolean;
  fetchUserInfo?: () => Promise<any>;
}> {
  const fetchUserInfo = () => {
    return InitService.getUserInfo();
  };

  // 如果不是登录页面，执行
  if (history.location.pathname !== loginPath) {
    const currentUser = await fetchUserInfo();

    return {
      currentUser,
      settings: defaultSettings,
      fetchUserInfo,
    };
  }

  return {
    currentUser: {},
    settings: defaultSettings,
    fetchUserInfo,
  };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
// @ts-ignore
export const layout: RunTimeLayoutConfig = ({ initialState, setInitialState }) => {
  const changeLayoutCollapsed = (flag: boolean) => {
    setInitialState({
      ...initialState,
      settings: {
        // @ts-ignore
        ...initialState.settings,
        // @ts-ignore
        collapsed: !!flag,
      },
    });
  };

  const onMenuItemClick = () => {
    // @ts-ignore
    const { collapsed } = initialState?.settings || {};

    changeLayoutCollapsed(!collapsed);
  };

  // 是否在 Admin 组里面
  const isAdminUser = AuthService.authorizedAdmin();

  const companyName = CommonService.getCompanyFromDomain();

  // 是否在 BD 组里面
  const isBD = AuthService.authorizedAdxBD();

  const mergeAdmin = isBD ? false : isAdminUser;

  return {
    ...initialState?.settings,
    title: companyName || 'ADX Platform',
    token: {
      bgLayout: '#eef0f3',
      header: {
        colorBgHeader: '#292f33',
        colorHeaderTitle: '#fff',
        colorTextMenu: '#dfdfdf',
        colorTextMenuSecondary: '#dfdfdf',
        colorTextMenuSelected: '#fff',
        colorBgMenuItemSelected: '#0064c8',
        colorTextMenuActive: 'rgba(255,255,255,0.85)',
        colorTextRightActionsItem: '#dfdfdf',
      },
      pageContainer: {
        paddingInlinePageContainerContent: 20,
        paddingBlockPageContainerContent: 20,
      },
    },
    locale: 'en-US',
    disableContentMargin: true,
    waterMarkProps: null,
    menuProps: {
      theme: 'dark',
    },
    menu: {
      // 每当 initialState?.currentUser?.id 发生修改时重新执行 request
      params: {
        userId: initialState?.currentUser?.id,
      },
      request: async () => {
        return await PermissionService.listMenu();
      },
    },
    onCollapse: changeLayoutCollapsed,
    rightContentRender: () => <RightContent />,
    menuItemRender: (menuItemProps, defaultDom: any) => {
      const { pathname } = history.location;

      return (
        <Link
          to={menuItemProps.path}
          onClick={onMenuItemClick}
          target={
            enterNewTabList.includes(pathname) ||
            enterNewTabList.includes(menuItemProps.path as string) ||
            (leaveNewTabList.includes(pathname) &&
              !leaveNewTabList.includes(menuItemProps.path as string))
              ? '_blank'
              : menuItemProps.target
          }
        >
          {defaultDom}
        </Link>
      );
    },
    // 增加一个 loading 的状态
    childrenRender: (children: any, props: any) => {
      return (
        <AdminUserContext.Provider value={mergeAdmin}>
          <CanCreateOperationContext.Provider value={!isBD}>
            <IsAdxBDContext.Provider value={isBD}>
              <App>{children}</App>
            </IsAdxBDContext.Provider>
          </CanCreateOperationContext.Provider>
        </AdminUserContext.Provider>
      );

      return (
        <>
          <AdminUserContext.Provider value={mergeAdmin}>
            <CanCreateOperationContext.Provider value={!isBD}>
              <IsAdxBDContext.Provider value={isBD}>
                <App>{children}</App>
              </IsAdxBDContext.Provider>
            </CanCreateOperationContext.Provider>
          </AdminUserContext.Provider>
          {!props.location?.pathname?.includes('/login') && (
            <SettingDrawer
              disableUrlParams
              enableDarkTheme
              settings={initialState?.settings}
              onSettingChange={(settings) => {
                setInitialState((preInitialState) => ({
                  ...preInitialState,
                  settings,
                }));
              }}
            />
          )}
        </>
      );
    },
  };
};
