import { getDvaApp } from 'umi';
import { isFunction } from 'lodash';
import request from '@/http';
import { CancelRequestReason } from '@/constant/api';

class Dispatch {
  dispatch = (slug) => {};

  constructor() {
    this.dispatch = (window?.g_app || getDvaApp())?._store?.dispatch || function () {};
  }

  /**
   * @description:创建一个可以取消的请求
   * @param{Function} method 使用fetch封装的请求函数 该函数需要将cancelToken加入到config里面
   * @param{object} payload 请求参数
   * **/
  cancelableRequest(method, payload) {
    if (isFunction(method)) {
      const { token, cancel } = request.CancelToken.source();

      return {
        run() {
          // method 需要处理一下混入的参数
          return method(payload, {
            cancelToken: token,
          });
        },
        cancel(reason) {
          cancel(reason || CancelRequestReason.Manual);
        },
      };
    }

    return {
      run() {
        return Promise.reject();
      },
      cancel(reason) {},
    };
  }
}

export default Dispatch;
