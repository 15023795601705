import { head, isArray, isNaN, nth, startsWith, toLower } from 'lodash';
import { EpDisabledReason, OperationRuleType } from '@/pages/operation/operation.enum';
import OperationDspSspConstant from '@/pages/operation/operation.constant';
import UserService from '@/pages/user/user.service';
import Dispatch from '@/class/Dispatch';
import ValidatorService from '@/services/validator.service';

class DspSspOperationService extends Dispatch {
  listDspSspEpConfigInAggregation(params) {
    this.dispatch({
      type: 'operationCommonState/listDspSspEpConfigInAggregation',
      payload: params,
    });
  }

  listInternalDspSspEpConfigInAggregation(params) {
    this.dispatch({
      type: 'operationCommonState/listInternalDspSspEpConfigInAggregation',
      payload: {
        ...params,
        dspId: OperationDspSspConstant.ONLINE_DSP_SUIBIAN_ID,
      },
    });
  }

  listDspSspEpConfigInOperation(params) {
    this.dispatch({
      type: 'operationCommonState/listDspSspEpConfigInOperation',
      payload: params,
    });
  }

  listInternalDspSspEpConfigInOperation(params) {
    this.dispatch({
      type: 'operationCommonState/listInternalDspSspEpConfigInOperation',
      payload: {
        ...params,
        dspId: OperationDspSspConstant.ONLINE_DSP_SUIBIAN_ID,
      },
    });
  }

  // ep / os config 2 body
  static dspSspEpConfigToBody(item) {
    if (item) {
      const { qps, floorIncrease, payoutDecrease, qpsRuleType } = item;

      const fixed = qpsRuleType === OperationRuleType.Fixed;

      const opened = qpsRuleType === OperationRuleType.Open;

      const floated = qpsRuleType === OperationRuleType.Float;

      const share = qpsRuleType === OperationRuleType.Share;

      return {
        qps,
        fixed,
        opened,
        share,

        floorIncrease: floorIncrease || 0,
        payoutDecrease: payoutDecrease || 0,
        floated: floated ? 1 : -1,
      };
    }

    return {};
  }

  // ssp ep 配置转body
  static approvedSspEpConfigToBody(list) {
    if (isArray(list)) {
      return list.map((item) => {
        const { sspIntegrationId, margin } = item;

        return {
          margin,
          sspIntegrationId,
        };
      });
    }

    return [];
  }

  // dsp ep 配置转body
  static approvedDspEpConfigToBody(list) {
    if (isArray(list)) {
      return list.map((item) => {
        // const { dspIntegrationId, geo, os } = item;
        //
        // const { qps, share, ...rest } = DspSspOperationService.dspSspEpConfigToBody(item);
        //
        // return {
        //   ...rest,
        //   qps: share ? -1 : qps,
        //   share,
        //   dspIntegrationId,
        //   geo,
        //   os,
        // };

        const { dspIntegrationId, margin } = item;

        return {
          dspIntegrationId,
          margin,
        };
      });
    }

    return [];
  }

  // qps rule 转form
  static mergeQpsRuleType = (item) => {
    const { fixed, floated, opened, share } = item;

    const data = {
      [OperationRuleType.Float]: floated !== -1,
      [OperationRuleType.Fixed]: fixed,
      [OperationRuleType.Open]: opened,
      [OperationRuleType.Share]: share,
    };

    const type = head(
      Object.keys(data).filter((key) => {
        return !!data[key];
      }),
    );

    // @ts-ignore
    return !isNaN(+type) ? +type : type;
  };

  static getDatacenterFromUnit(unit) {
    if (unit && unit.includes('-')) {
      // 名字里面可能带有'-' 倒着取
      const datacenterList = unit.split('-').slice(-2);

      if (datacenterList.length > 0) {
        return nth(datacenterList, 0);
      }

      return '';
    }

    return '';
  }

  static getBrandNameFromUnit(unit) {
    if (unit && unit.includes('-')) {
      const name = head(unit.split('-'));

      if (name) {
        return name.includes('#') ? head(unit.split('#')) : name;
      }

      return '';
    }

    return '';
  }

  static getDisableReason(obj) {
    const ret = [];

    if (obj) {
      if (obj.suiBianDatacenter) {
        ret.push(EpDisabledReason.SuibianDatacenter);
      }

      if (obj.suiBianClosedBudget) {
        ret.push(EpDisabledReason.SuiBianClosedBudget);
      }

      if (obj.dcDatacenter) {
        ret.push(EpDisabledReason.DcDatacenter);
      }

      if (obj.sameBrand) {
        ret.push(EpDisabledReason.SameBrand);
      }

      if (obj.approved) {
        ret.push(EpDisabledReason.Approved);
      }

      if (!obj.approved && obj.approvedOPA) {
        ret.push(EpDisabledReason.ApprovedInOPA);
      }

      if (!obj.approved && obj.approvedOPB) {
        ret.push(EpDisabledReason.ApprovedInOPB);
      }

      if (obj.internalDsp) {
        ret.push(EpDisabledReason.InternalDsp);
      }
    }

    return ret;
  }

  // with same brand
  static transformSspEpStatusFromDspUnit(list, dsp, configList) {
    if (isArray(list)) {
      const isExternalUser = UserService.getIsExternalUser();

      const approvedSspEpIds = isArray(configList)
        ? configList.map((item) => item.sspIntegrationId)
        : [];

      const dspDataCenter = DspSspOperationService.getDatacenterFromUnit(dsp.dspUnit);

      const dspBrand = DspSspOperationService.getBrandNameFromUnit(dsp.dspUnit);

      return list.map((item) => {
        const sspDataCenter = DspSspOperationService.getDatacenterFromUnit(item.sspUnit);

        const sspBrand = DspSspOperationService.getBrandNameFromUnit(item.sspUnit);

        const isRvInHouse = item.sspUnit?.includes(
          OperationDspSspConstant.ONLINE_RV_INHOUSE_PREFIX,
        );

        // datacenter不一致
        const disableWithDatacenter = sspDataCenter !== dspDataCenter;

        const mergeSuibianStatus = isRvInHouse || disableWithDatacenter;

        const isDisabledSuibian =
          dsp?.dspId === OperationDspSspConstant.ONLINE_DSP_SUIBIAN_ID ? mergeSuibianStatus : false;

        // 同品牌禁用，自己不给批给自己
        const dspSspSameBrand = toLower(dspBrand) === toLower(sspBrand);

        // 已经批过
        const isApproved = approvedSspEpIds.length
          ? approvedSspEpIds.includes(item.sspIntegrationId)
          : false;

        // 131 ssp 需要受datacenter限制
        const isDisabledDc =
          item.sspId === OperationDspSspConstant.ONLINE_SSP_DC_ID ? disableWithDatacenter : false;

        const reasonObj = {
          suiBianDatacenter: !isExternalUser && isDisabledSuibian,
          dcDatacenter: !isExternalUser && isDisabledDc,

          sameBrand: dspSspSameBrand,
          approvedOPA: isApproved,
        };

        // 被禁用的理由
        const reason = DspSspOperationService.getDisableReason(reasonObj);

        const disabled =
          (!isExternalUser && (isDisabledSuibian || dspSspSameBrand || isDisabledDc)) || isApproved;

        return {
          ...item,
          disabled,
          title: reason.join('.\n'),
        };
      });
    }

    return [];
  }

  static getApprovedSspOptions(list) {
    if (isArray(list)) {
      return list.map((item) => {
        const { sspIntegrationId, sspUnit } = item;

        return {
          id: sspIntegrationId,
          name: sspUnit,
        };
      });
    }

    return [];
  }

  static compareArrayOneRepeat(source, target) {
    if (!source?.length || !target?.length) {
      return false;
    }

    return source.length > 0 ? target.some((str) => source.includes(str)) : false;
  }

  static sortSspOperations(operations, sortList) {
    if (isArray(operations) && isArray(sortList) && sortList.length) {
      return operations
        .map((item) => {
          const sorter = sortList.find((child) => child.sspIntegrationId === item.sspIntegrationId);

          if (sorter) {
            return {
              ...item,
              updateTime: sorter.time,
            };
          }

          return item;
        })
        .sort((a, b) => {
          return b.updateTime - a.updateTime;
        });
    }

    return operations;
  }

  static dspBlockDetailToForm(unit) {
    const { ips, pubIds, bundleIds } = unit;

    return {
      ips,
      pubIds,
      bundleIds,
    };
  }

  static mergeDspConfigApproveDetail(config, detail, dspItem) {
    const existConfig =
      config && (config.approveHighWeights?.length || config.approveWhiteLists?.length);

    // 第一次审批
    if (!detail) {
      const merge = {
        highWeights: [],
        whiteLists: [],
      };

      // dsp 存在通用配置
      if (existConfig) {
        const { approveHighWeights, approveWhiteLists } = config;

        const lowerOsName = toLower(dspItem?.osName);

        const highWeightsBundle = DspSspOperationService.splitBundleIds(approveHighWeights);

        const whiteListsBundle = DspSspOperationService.splitBundleIds(approveWhiteLists);

        const isIOS = lowerOsName === 'ios';

        merge.highWeights = isIOS
          ? highWeightsBundle.iosBundleIds
          : highWeightsBundle.androidBundleIds;

        merge.whiteLists = isIOS
          ? whiteListsBundle.iosBundleIds
          : whiteListsBundle.androidBundleIds;

        if (merge.highWeights.length && merge.whiteLists.length) {
          merge.highWeights = [];
        }
      }

      return merge;
    }

    return detail;
  }

  static mergeDspConfigBlockDetail(config, detail) {
    const existConfig =
      config &&
      (config.blockIps?.length ||
        config.blockBundleIds?.length ||
        config.blockPubIds?.length ||
        config.blockCreativeIds?.length);

    if (existConfig && !detail) {
      const { blockIps = [], blockBundleIds, blockPubIds = [] } = config;

      return {
        ips: blockIps,
        pubIds: blockPubIds,
        bundleIds: blockBundleIds,
      };
    }

    return detail || {};
  }

  static generalSspSortList(ids) {
    if (isArray(ids)) {
      return ids.map((id) => ({
        sspIntegrationId: id,
        time: +new Date(),
      }));
    }

    return [];
  }

  static splitBundleIds(bundleIds) {
    const androidBundleIds = [];

    const iosBundleIds = [];

    if (isArray(bundleIds) && bundleIds.length) {
      bundleIds.forEach((bundleId) => {
        const lower = toLower(bundleId);

        // iOS
        if (startsWith(lower, 'id') || !lower.includes('.')) {
          iosBundleIds.push(bundleId);

          return;
        }

        // Android
        androidBundleIds.push(bundleId);
      });

      return {
        androidBundleIds,
        iosBundleIds,
      };
    }

    return {
      androidBundleIds,
      iosBundleIds,
    };
  }

  static generatorOperationId(item) {
    const { id: dspIntegrationId, dspId, geo, adFormat } = item;

    return [dspId, dspIntegrationId, geo, adFormat].join('_');
  }

  // 聚合dsp offer id
  static generateOfferAdsetId(item) {
    const { offerId, adSetId, adFormat, countryId, os } = item;

    return `${offerId}_${adSetId}_${adFormat}_${countryId}_${os}`;
  }

  // 解析dsp offer id
  static parseOfferAdsetId(string) {
    if (string && string.includes('_')) {
      const [offerId, adSetId, adFormat, countryId, os] = string.split('_');

      return {
        offerId: !isNaN(+offerId) ? +offerId : offerId,
        adSetId: !isNaN(+adSetId) ? +adSetId : adSetId,
        countryId: !isNaN(+countryId) ? +countryId : countryId,
        os: !isNaN(+os) ? +os : os,

        adFormat,
      };
    }

    return {
      offerId: null,
      adSetId: null,
      adFormat: null,
      countryId: null,
      os: null,
    };
  }

  // 检测fields里面的字符有没有在 changedFields里面出现过
  static fieldsChanged(changedFields, fields) {
    if (isArray(changedFields) && changedFields.length) {
      return fields.some((key) => {
        return changedFields.some((field) => field.includes(key));
      });
    }

    return false;
  }

  // form 苹果bundle id在填入的时候不能以id开头
  static validAppleBundleNotStartWithId(bundleIds) {
    if (isArray(bundleIds)) {
      const withIdAppleBundle = bundleIds.filter((bundleId) =>
        ValidatorService.isValidAppleBundleId(bundleId),
      );

      if (withIdAppleBundle.length) {
        return Promise.reject(
          `'${withIdAppleBundle.join(', ')}' apple bundle id needs to remove id character`,
        );
      }
    }

    return Promise.resolve();
  }

  /**@description:格式化dsp ssp unit名字 移除掉名字里面的'-'
   * @description:输入: "a1-b1-US-banner" => 输出: "a1b1-US-banner"
   * **/
  static replaceDspSspUnitSymbol(unit) {
    // 正则表达式解释：
    // /-(?=.*?-.*?-)/g
    // 匹配所有 '-'，其中后面至少还有两个 '-'。
    if (unit) {
      return unit.replace(/-(?=.*?-.*?-)/g, '');
    }

    return unit;
  }
}

export default DspSspOperationService;
