import { uniqBy, isArray } from 'lodash';
import Dispatch from '@/class/Dispatch';
import {
  operationSspBudget,
  deleteSspBudgetUnit,
  listAdSetSspUnit,
  listSspUnitBidRate,
  listSetSspBudgetUnit,
  listBidRateMemos,
  listBidRateUpdateMemos,
} from '@/models/rtb/by-ssp-unit/api';

import { DspOperationStateEnum } from '@/models/operation/ssp/enum';

class BySspBudgetUnitService extends Dispatch {
  listSspBudgetUnit(body) {
    return this.dispatch({
      type: 'bySspUnitState/listSspBudgetUnit',
      payload: body,
    });
  }

  static listBidRateMemos(id) {
    return listBidRateMemos(id);
  }

  static listBidRateUpdateMemos(id) {
    return listBidRateUpdateMemos(id);
  }

  static operationSspBudget(body) {
    return operationSspBudget(body);
  }

  static deleteSspBudgetUnit(ids) {
    return deleteSspBudgetUnit(ids);
  }

  static listAdSetSspUnit(params) {
    return listAdSetSspUnit(params);
  }

  static listSspUnitBidRate(id) {
    return listSspUnitBidRate(id);
  }

  static listSetSspBudgetUnit(body) {
    return listSetSspBudgetUnit(body);
  }

  static getAvailableSspEpDropdown(list) {
    if (isArray(list) && list.length) {
      return uniqBy(list, 'sspIntegrationId')
        .filter((item) => item.internalDspBudgetSwitch === DspOperationStateEnum.ON)
        .map((item) => {
          const { sspUnit, sspIntegrationId } = item;

          return {
            id: sspIntegrationId,
            name: `${sspUnit}#${sspIntegrationId}`,
          };
        });
    }

    return [];
  }
}

export default BySspBudgetUnitService;
