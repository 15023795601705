import { head, isArray, isNaN, toLower } from 'lodash';
import Dispatch from '@/class/Dispatch';
import {
  reFloatRule,
  blockSspUnit,
  deleteSspUnit,
  detailBlockSspUnit,
  getSspEpIntegrationConfig,
  listSspUnitOsFloorRange,
  updateSspUnitOsFloorRange,
  detailSspUnitApproveDetail,
  updateSspUnitApprovedConfig,
  approveSspUnit,
  listSspUnitApprovedConfig,
  reFloatOsRule,
  detailSspUnitApprovedBudget,
  listSspEpTarget,
  approveSspEpForDspEpBudget,
  listSspUnitApprovalOsConfig,
} from '@/models/operation/ssp-unit/api';
import { DspOperationStateEnum } from '@/models/operation/ssp-unit/enum';
import Utils from '@/utils';
import DspMultipleApproveService from '@/pages/operation/ssp/approve/service';
import NumberFormatService from '@/services/numberformat.service';
import AdSetConstant from '@/pages/rtb/ad-set/ad-set.constant';
import BySspBudgetUnitService from '@/pages/rtb/by-ssp-unit/service';
import DspSspOperationService from '@/pages/operation/operation.service';
import OperationDspSspConstant from '@/pages/operation/operation.constant';
import SspEpOperationService from '@/pages/operation/ssp-ep/service';

class SspUnitOperationService extends Dispatch {
  listSspUnit(params) {
    this.dispatch({
      type: 'operationSspUnit/listSspUnit',
      payload: params,
    });
  }

  listOperationDspApproveUnit(params) {
    this.dispatch({
      type: 'operationSspUnit/listOperationDspApproveUnit',
      payload: params,
    });
  }

  listOperationDspBlockUnit(params) {
    this.dispatch({
      type: 'operationSspUnit/listOperationDspBlockUnit',
      payload: params,
    });
  }

  updateOperationState(params) {
    this.dispatch({
      type: 'operationSspUnit/updateOperationState',
      payload: params,
    });
  }

  updateFilterFormDspOptions(list) {
    this.dispatch({
      type: 'operationSspUnit/updateFilterFormDspOptions',
      payload: list,
    });
  }

  updateApprovedDspOptions(list) {
    this.dispatch({
      type: 'operationSspUnit/updateApprovedDspOptions',
      payload: list,
    });
  }

  listDspOffer(params) {
    this.dispatch({
      type: 'operationSspUnit/listDspOffer',
      payload: params,
    });
  }

  listMatchedSspEp(params) {
    this.dispatch({
      type: 'operationSspUnit/listMatchedSspEp',
      payload: params,
    });
  }

  listInternalDspEp(params) {
    this.dispatch({
      type: 'operationSspUnit/listInternalDspEp',
      payload: params,
    });
  }

  // 获取ep config
  static getSspEpIntegrationConfig(integrationId) {
    return getSspEpIntegrationConfig(integrationId);
  }

  static updateSspUnitOsFloorRange(data) {
    return updateSspUnitOsFloorRange(data);
  }

  static listSspUnitOsFloorRange(data) {
    return listSspUnitOsFloorRange(data);
  }

  // 审批dsp ep config
  static updateSspUnitApprovedConfig(list) {
    if (isArray(list) && list.length) {
      if (list.length > 1) {
        list.forEach((item) => updateSspUnitApprovedConfig(item));

        return;
      }

      return updateSspUnitApprovedConfig(list[0]);
    }

    return Promise.reject();
  }

  static approveSspUnit(body) {
    return approveSspUnit(body);
  }

  static detailSspUnitApprovedBudget(data) {
    return detailSspUnitApprovedBudget(data);
  }

  static listSspEpTarget(data) {
    return listSspEpTarget(data);
  }

  static approveSspEpForDspEpBudget(data) {
    return approveSspEpForDspEpBudget(data);
  }

  static blockSspUnit(body) {
    return blockSspUnit(body);
  }

  static deleteSspUnit(body) {
    return deleteSspUnit(body);
  }

  static detailSspUnitApproveDetail(body) {
    return detailSspUnitApproveDetail(body);
  }

  static async listSspUnitApprovedConfig(params) {
    const approved = await listSspUnitApprovedConfig(params);

    const matched = await listSspUnitApprovalOsConfig(params);

    return SspUnitOperationService.mergeSspApprovedDspEpOsConfig(approved, matched);
  }

  // 合并已审批和待审批 dsp ep os配置
  static mergeSspApprovedDspEpOsConfig(approved, matched) {
    const generatorMatchedId = (item) => {
      const { dspIntegrationId, geo, os } = item;

      return [dspIntegrationId, geo, os].join('_');
    };

    const formatApproved = isArray(approved)
      ? approved.map((item) => ({ ...item, uniqueId: generatorMatchedId(item) }))
      : [];

    const osList = isArray(matched)
      ? matched.map((matchedItem) => {
          const mergeMatchedId = generatorMatchedId(matchedItem);

          const flag = formatApproved.find((item) => item.uniqueId === mergeMatchedId);

          return {
            ...flag,
            ...matchedItem,
            uniqueId: mergeMatchedId,
          };
        })
      : [];

    return {
      osList,
      osSelectorData: osList.map((item) => ({ id: item.uniqueId, name: item.osName })),
      osSelected: formatApproved.map((item) => item.uniqueId),
    };
  }

  static detailBlockSspUnit(params) {
    return detailBlockSspUnit(params);
  }

  static deleteOfferAdsetDspSspBudget(ids) {
    return BySspBudgetUnitService.deleteSspBudgetUnit(ids);
  }

  static reFloatRule(dspOperationId) {
    return reFloatRule(dspOperationId);
  }

  static reFloatOsRule(dspOperationId) {
    return reFloatOsRule(dspOperationId);
  }

  static getOperationParams(item) {
    if (!Utils.falsely(item)) {
      const { id: sspIntegrationId, geo: countryId, adFormat } = item;

      return {
        sspIntegrationId,
        countryId,
        adFormat,
      };
    }

    return {
      sspIntegrationId: null,
      countryId: null,
      adFormat: null,
    };
  }

  static generalDspSortList(ids) {
    if (isArray(ids)) {
      return ids.map((id) => ({
        dspIntegrationId: id,
        time: +new Date(),
      }));
    }

    return [];
  }

  static mergeConfigBlockDetail(config, detail) {
    const existConfig = config && config.blockCreativeIds?.length;

    if (existConfig && !detail) {
      const { blockCreativeIds } = config;

      return {
        ...config,
        creativeIds: blockCreativeIds,
      };
    }

    return detail;
  }

  static transformApprovedDspOsConfig(dspOperations) {
    return isArray(dspOperations) && dspOperations.length
      ? dspOperations.map((item) => {
          const {
            qps,
            floorIncrease,
            payoutDecrease,
            fixed,
            floated,
            opened,
            qpsFloatRulesName,
            id,
            dspSspApproveId,
            dspIntegrationId,

            os,
            osName,
            geo,
            geoName,
            uniqueId,
          } = item;

          const qpsFloatRule = {
            ruleId: floated,
            originRuleId: floated,
            fixed,
            qpsFloatRulesName,
            opened,
          };

          const qpsRuleType = DspSspOperationService.mergeQpsRuleType(item);

          return {
            floorIncrease: floorIncrease || 0,
            payoutDecrease: payoutDecrease || 0,
            qps: !Utils.falsely(qps) && qps !== -1 ? qps : null,
            dspSspApproveId,
            qpsRuleType,
            id,
            qpsFloatRule,
            dspIntegrationId,

            os,
            osName,
            geo,
            geoName,
            uniqueId,
          };
        })
      : [];
  }

  // dsp审批详情转换成表单
  static sspUnitApprovedDetailToForm(dspOperations) {
    const mergeDspOperations = SspUnitOperationService.transformApprovedDspOsConfig(dspOperations);

    return {
      externalDspOperations: mergeDspOperations,
      externalDspEp: mergeDspOperations.map((item) => item.dspIntegrationId),
    };
  }

  // 预算详情转换成表单
  static sspUnitApproveBudgetToForm(detail) {
    if (detail) {
      const { internalDspOperationSwitch, internalDspSspOfferAdSetBudgets } = detail;

      const mergeInternalDspSspOfferAdSetBudgets = isArray(internalDspSspOfferAdSetBudgets)
        ? internalDspSspOfferAdSetBudgets.map((item) => {
            const {
              offerId,
              offerName,
              adSetId,
              adSetName,
              adFormat,
              budget,
              payoutIncrease = 0,
              bidRates,

              ...rest
            } = item;

            const id = DspSspOperationService.generateOfferAdsetId(item);

            return {
              ...rest,
              offerId,
              offerName,
              adSetId,
              adSetName,
              adFormat,
              budget,
              payoutIncrease,
              dspOfferId: id,
              bidRates:
                isArray(bidRates) && bidRates.length ? bidRates : AdSetConstant.defaultBidRate,
            };
          })
        : [];

      const uniqueBudgetList = Utils.uniqueArrayByKey(
        mergeInternalDspSspOfferAdSetBudgets,
        'dspOfferId',
      );

      const dspOfferId = uniqueBudgetList.map((item) => item.dspOfferId);

      return {
        dspOfferId,
        internalDspOperationSwitch: internalDspOperationSwitch === DspOperationStateEnum.ON,
        internalDspSspOfferAdSetBudgets: uniqueBudgetList,
      };
    }

    return {};
  }

  static getApprovedDspOptions(list) {
    if (isArray(list)) {
      return list.map((item) => {
        const { dspIntegrationId, dspUnit } = item;

        return {
          id: dspIntegrationId,
          name: dspUnit,
        };
      });
    }

    return [];
  }

  static sortDspOperations(operations, sortList) {
    if (isArray(operations) && isArray(sortList) && sortList.length) {
      return operations
        .map((item) => {
          const sorter = sortList.find((child) => child.dspIntegrationId === item.dspIntegrationId);

          if (sorter) {
            return {
              ...item,
              updateTime: sorter.time,
            };
          }

          return item;
        })
        .sort((a, b) => {
          return b.updateTime - a.updateTime;
        });
    }

    return operations;
  }

  // 内部被审批的dsp表单数据转换成提交数据
  static internalDspOperations2Body(list, internalDspEpList, dspOffer, sspList) {
    if (isArray(list) && list.length) {
      const matchedOffer = head(dspOffer.filter((item) => !item.disabled));

      const matchedInternalDspEpList = Utils.uniqueArrayByKey(
        internalDspEpList
          .filter((dsp) => dsp.matchedId === matchedOffer?.matchedId)
          .map((dsp) => {
            return {
              ...dsp,
              qps: 1000,
              floorIncrease: 0,
              payoutDecrease: 0,
              fixed: true,
              floated: -1,
              opened: 1,
              reFloated: false,
              share: false,
            };
          }),
        'dspIntegrationId',
      );

      const offerAdSetBudgets = [];

      matchedInternalDspEpList.forEach((dsp) => {
        list.forEach((item) => {
          const { dspOfferId, payoutIncrease = 0, bidRates, ...rest } = item;

          const offerItem = dspOffer.find((offer) => offer.id === dspOfferId);

          if (offerItem) {
            const { offerId, adSetId, adFormat } =
              DspSspOperationService.parseOfferAdsetId(dspOfferId);

            if (sspList && sspList.length) {
              sspList.forEach((ssp) => {
                const { countryId, os, sspId, sspIntegrationId } = ssp;

                const { dspId, dspIntegrationId } = dsp;

                const budget = {
                  ...rest,
                  dspId,
                  dspIntegrationId,
                  offerId,
                  adSetId,
                  adFormat,
                  countryId,
                  os,
                  sspId,
                  sspIntegrationId,
                  bidRates:
                    isArray(bidRates) && bidRates.length ? bidRates : AdSetConstant.defaultBidRate,
                  payoutIncrease: NumberFormatService.toFixed(payoutIncrease),
                };

                offerAdSetBudgets.push(budget);
              });
            }
          }
        });
      });

      return {
        internalDspList: matchedInternalDspEpList,
        offerAdSetBudgets,
      };
    }

    return {
      internalDspList: [],
      offerAdSetBudgets: [],
    };
  }

  // 找出被移除的预算
  static getNeedDeleteSspBudget(detailDspOperations, formDspOperations) {
    // 历史预算id
    const ids = isArray(detailDspOperations)
      ? detailDspOperations.filter((item) => !Utils.falsely(item.id)).map((item) => item.id)
      : [];

    // 表单提交还剩余的id
    const formDspOperationIds = isArray(formDspOperations)
      ? formDspOperations.filter((item) => !Utils.falsely(item.id)).map((item) => item.id)
      : [];

    if (ids.length) {
      return formDspOperationIds.length === 0
        ? ids
        : formDspOperationIds.filter((id) => !ids.includes(id));
    }

    return [];
  }

  // 跟内部dsp不匹配的offer
  static disableNotMatchedInternalDspEp(externalSspEps, internalDspEps, dspOffers) {
    if (isArray(dspOffers) && dspOffers.length) {
      const matchedIds = isArray(internalDspEps)
        ? internalDspEps.map((item) => item.matchedId)
        : [];

      const matchedSspEpIds = isArray(externalSspEps)
        ? externalSspEps.map((item) => item.matchedId)
        : [];

      const intersectionDspSspIds = Utils.intersection(matchedIds, matchedSspEpIds);

      return dspOffers.map((offer) => {
        // const offerMatchedId =
        //   offer.matchedId || SspUnitOperationService.generateMatchedInternalDspKey(offer);

        const offerMatchedId = offer.matchedId || SspEpOperationService.genMatchedUniqueId(offer);

        const disabled = !intersectionDspSspIds.includes(offerMatchedId);

        return {
          ...offer,
          disabled,
        };
      });
    }

    return [];
  }

  static generateMatchedInternalDspKey(item) {
    // const { countryId, os } = item;
    const { countryId, adFormat } = item;
    // const { countryId, os, adFormat } = item;

    // return `${countryId}_${os}_${adFormat}`;
    // return `${countryId}_${os}`;
    return `${countryId}_${adFormat}`;
  }

  static checkExistedBudgetEqualsZero(list) {
    if (isArray(list)) {
      return list.some((item) => item.hasOwnProperty('budget') && item.budget === 0);
    }

    return false;
  }

  static sspUnitDspOsListToBody(list, common) {
    if (isArray(list) && list.length) {
      return list.reduce((curr, item) => {
        const { osSelected = [], approvedDspOs = [] } = item;

        // 过滤掉未选中的
        const mergeApprovedSspOs =
          isArray(approvedDspOs) && isArray(osSelected)
            ? approvedDspOs.filter((child) => osSelected.includes(child.uniqueId))
            : [];

        if (mergeApprovedSspOs.length) {
          const osConfig = DspSspOperationService.approvedDspEpConfigToBody(mergeApprovedSspOs);

          const mergeItem = {
            ...common,
            dspIntegrationId: item.dspIntegrationId,
            dspOperations: osConfig,
          };

          curr = [...curr, mergeItem];
        }

        return curr;
      }, []);
    }

    return [];
  }

  // 是否dsp ep 跟ssp是否是同一个品牌
  static transformDspEpItem(dspEpList, ssp) {
    if (isArray(dspEpList)) {
      const sspBrand = DspSspOperationService.getBrandNameFromUnit(ssp.sspUnit);

      return dspEpList.map((item) => {
        const dspBrand = DspSspOperationService.getBrandNameFromUnit(item.dspUnit);

        const isInternalDsp = item.dspId === OperationDspSspConstant.ONLINE_DSP_SUIBIAN_ID;

        // 同品牌禁用，自己不给批给自己
        const dspSspSameBrand = toLower(dspBrand) === toLower(sspBrand);

        return {
          ...item,
          disabled: dspSspSameBrand || isInternalDsp,
        };
      });
    }

    return [];
  }

  static transformSspUnitBidFloorPrice(list) {
    if (isArray(list) && list.length) {
      return list.map((item) => {
        const {
          sspIntegrationId,
          countryId,
          geoName,
          os,
          osName,
          bidPriceRangeMin,
          bidPriceRangeMax,
        } = item;

        return {
          sspIntegrationId,
          countryId,
          geoName,
          os,
          osName,
          bidPriceRangeMin,
          bidPriceRangeMax,
        };
      });
    }

    return [];
  }

  static floorPriceRangeByOs2Body(list) {
    if (isArray(list) && list.length) {
      return list.map((item) => {
        const { sspIntegrationId, countryId, os, bidPriceRangeMin, bidPriceRangeMax } = item;

        return {
          sspIntegrationId,
          countryId,
          os,
          bidPriceRangeMin,
          bidPriceRangeMax,
        };
      });
    }
  }

  // 聚合id
  static generatorOperationId(item) {
    const { id: sspIntegrationId, geo: countryId, adFormat } = item;

    return [sspIntegrationId, countryId, adFormat].join('_');
  }

  // 解析聚合id
  static decodeOperationId(operationId) {
    if (operationId && operationId.includes('_')) {
      const [sspIntegrationId, countryId, os, adFormat] = operationId
        .split('_')
        ?.map((item) => DspMultipleApproveService.number(item));

      return {
        sspIntegrationId,
        countryId,
        os,
        adFormat,
      };
    }

    return {
      sspIntegrationId: null,
      countryId: null,
      os: null,
      adFormat: null,
    };
  }

  static sspBudgetChanged(fields) {
    const budgetFields = [
      'dspOfferId',
      'internalDspSspOfferAdSetBudgets',
      'internalDspOperationSwitch',
    ];

    return DspSspOperationService.fieldsChanged(fields, budgetFields);
  }
}

export default SspUnitOperationService;
