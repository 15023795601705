import { defaultListData } from '@/models/common.js';
import {
  listSspEp,
  listOperationDspApproveUnit,
  listOperationDspBlockUnit,
  listDspOffer,
  listInternalDspEp,
  listMatchedSspEp,
} from './api';
import { transformSspEpList } from './util';
import {
  transformOfferAdset,
  transformDspEpItems,
  transformInternalDspEp,
  transformExternalSspEp,
} from '@/models/operation/ssp-unit/util';
import SspUnitOperationService from '@/pages/operation/ssp-unit/service';

const operationSspEpModel = {
  namespace: 'operationSspEp',
  state: {
    sspEpData: defaultListData,
    approveUnitSelectList: [],
    blockUnitSelectList: [],
    dspOffer: [],
    operationState: {},
    hasOperatedIds: [],
    filterFormDspOptions: [],
    approvedDspOptions: [],

    internalDspEpList: [],
    externalSspEpTargetList: [],
  },
  effects: {
    *listSspEp({ payload }, { put, call }) {
      const data = yield call(listSspEp, payload);

      yield put({
        type: 'listSspEpSuccess',
        payload: data,
      });
    },

    *listOperationDspApproveUnit({ payload }, { put, call }) {
      const data = yield call(listOperationDspApproveUnit, payload);

      yield put({
        type: 'listOperationDspApproveUnitSuccess',
        payload: data,
      });
    },

    *listOperationDspBlockUnit({ payload }, { put, call }) {
      const data = yield call(listOperationDspBlockUnit, payload);

      yield put({
        type: 'listOperationDspBlockUnitSuccess',
        payload: data,
      });
    },

    *listDspOffer({ payload }, { put, call }) {
      const internalDspEpList = yield call(listInternalDspEp, payload);

      const externalSspEpTargetList = yield call(listMatchedSspEp, payload);

      const dspOffer = yield call(listDspOffer, payload);

      yield put({
        type: 'listDspOfferSuccess',
        payload: {
          dspOffer,
          internalDspEpList,
          externalSspEpTargetList,
        },
      });
    },

    *listInternalDspEp({ payload }, { put, call }) {
      const data = yield call(listInternalDspEp, payload);

      yield put({
        type: 'listInternalDspEpSuccess',
        payload: data,
      });
    },
  },
  reducers: {
    listSspEpSuccess(state, { payload }) {
      const { list, paginator } = payload;

      return {
        ...state,
        sspEpData: {
          list: transformSspEpList(list),
          paginator,
        },
      };
    },

    listOperationDspApproveUnitSuccess(state, { payload }) {
      return {
        ...state,
        approveUnitSelectList: transformDspEpItems(payload),
      };
    },

    listOperationDspBlockUnitSuccess(state, { payload }) {
      return {
        ...state,
        blockUnitSelectList: transformDspEpItems(payload),
      };
    },

    updateOperationState(state, { payload }) {
      const { id, isLoading } = payload;

      const { operationState, hasOperatedIds } = state;

      const flag = hasOperatedIds.includes(id);

      operationState[id] = isLoading;

      if (!flag) {
        hasOperatedIds.push(id);
      }

      return {
        ...state,
        hasOperatedIds: [...hasOperatedIds],
        operationState: { ...operationState },
      };
    },

    updateFilterFormDspOptions(state, { payload }) {
      return {
        ...state,
        filterFormDspOptions: payload?.map((item) => ({
          ...item,
          name: `${item.name}#${item.id}`,
        })),
      };
    },

    updateApprovedDspOptions(state, { payload }) {
      return {
        ...state,
        approvedDspOptions: payload,
      };
    },

    listDspOfferSuccess(state, { payload }) {
      const { dspOffer = [], internalDspEpList = [], externalSspEpTargetList = [] } = payload;

      // offer
      const mergedDspOffer = transformOfferAdset(dspOffer);

      // 内部dsp ep
      const mergeInternalDspEpList = transformInternalDspEp(internalDspEpList);

      // 审批的ssp ep target列表
      const mergeExternalSspEpTargetList = transformExternalSspEp(externalSspEpTargetList);

      const formatDspOffer = SspUnitOperationService.disableNotMatchedInternalDspEp(
        mergeExternalSspEpTargetList,
        mergeInternalDspEpList,
        mergedDspOffer,
      );

      const availableDspOffers = formatDspOffer.filter((item) => !item.disabled);

      return {
        ...state,
        dspOffer: availableDspOffers,
        internalDspEpList: mergeInternalDspEpList,
        externalSspEpTargetList: mergeExternalSspEpTargetList,
      };
    },

    listInternalDspEpSuccess(state, { payload }) {
      return {
        ...state,
        internalDspEpList: transformInternalDspEp(payload),
      };
    },
  },
};

export default operationSspEpModel;
