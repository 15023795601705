import numeral from 'numeral';
import { isNumber, isNaN } from 'lodash';

class NumberFormatService {
  // 百分比
  static formatPercentage(number: number, fmt = '0.[0000]%') {
    if (isNumber(number)) {
      return numeral(number).format(fmt);
    }

    return '--';
  }

  // 数字
  static formatNumber(num: number, fmt = '0,0.[0000]') {
    if (isNumber(num)) {
      // 科学计数法
      if (String(num).includes('e')) {
        return +num.toFixed(6);
      }

      return numeral(num).format(fmt);
    }

    return '--';
  }

  // 格式化科学计数法
  static toNonExponential(num: number) {
    const m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);

    // @ts-ignore
    return num.toFixed(Math.max(0, (m[1] || '').length - m[2]));
  }

  /**
   * @description:格式化文件单位
   * @param{number} size 大小 单位 byte
   * * */
  static formatFileSize(size: number) {
    return isNumber(size) ? numeral(size).format('0b') : '--';
  }

  // 2022121204 => 2022-12-12 04
  static formatPeriod(period: number) {
    if (isNumber(period)) {
      const source = String(period);

      const year = source.substring(0, 4);

      const month = source.substring(4, 6);

      const day = source.substring(6, 8);

      const hour = source.substring(8, 10);

      return [year, month, day, hour].filter(Boolean).join('-');
    }

    return period;
  }

  static toDecimal(number: number, size = 2) {
    // @ts-ignore
    const result = parseFloat(number);

    if (isNaN(+result)) {
      return 0;
    }

    const fmt = 10 ** size;

    return Math.round(number * fmt) / fmt;
  }

  static toFixed(number: number, fmt = '0.[00]') {
    if (!isNaN(+number)) {
      return +numeral(number).format(fmt);
    }

    return 0;
  }
}

export default NumberFormatService;
