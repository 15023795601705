import { replace } from 'lodash';
import UserService from '@/pages/user/user.service';
import { getRtbTrackingConfig } from '@/models/rtb/creative/api';

class RtbService {
  static async getRtbTrackingConfig() {
    const config = await getRtbTrackingConfig();

    RtbService.updateTrackingConfig(config);

    return config;
  }

  static updateTrackingConfig(config) {
    localStorage.setItem('rtbTrackingConfig', JSON.stringify(config));
  }

  static getTrackingConfig() {
    try {
      const config = JSON.parse(localStorage.getItem('rtbTrackingConfig'));

      return config || {};
    } catch (e) {}
  }

  // 用于更新素材埋点时，保证tracking params 是最新的
  static refreshRtbTrackingParams() {
    return RtbService.getRtbTrackingConfig();
  }

  getUri() {
    const config = RtbService.getTrackingConfig();

    const user = UserService.getLocalUser();

    const mergeConfig = config?.domain ? config : user?.trackingConfig;

    if (mergeConfig) {
      const { domain, clickUri, impressionUri, params, reqAttrUri } = mergeConfig;

      // 如果其中有问号 移除
      const [mergeDomain, mergeClickUri, mergeImpressionUri, mergeParams] = [
        domain,
        clickUri,
        impressionUri,
        params,
      ].map((string) => (string ? replace(string, '?', '') : ''));

      const click = `${mergeDomain}${mergeClickUri}?${mergeParams}`;

      const impression = `${mergeDomain}${mergeImpressionUri}?${mergeParams}`;

      // 获取通用属性 客户端 ip等信息
      const reqAttribute = `${mergeDomain}${reqAttrUri}`;

      return {
        click,
        impression,
        reqAttribute,
      };
    }

    return {
      click: null,
      impression: null,
      reqAttribute: null,
    };
  }

  getReqAttributeUrl() {
    const url = this.getUri();

    return url.reqAttribute;
  }

  getClickUrl() {
    const url = this.getUri();

    return url.click;
  }

  getClickFailUrl() {
    const url = this.getUri();

    return `${url.click}&failed=1`;
  }

  getImpressionUrl() {
    const url = this.getUri();

    return url.impression;
  }

  getImpressionFailUrl() {
    const url = this.getUri();

    return `${url.impression}&failed=1`;
  }
}

export default RtbService;
