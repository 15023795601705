import React from 'react';

// 是否是管理员
const AdminUserContext = React.createContext(false);

// 是否可以使用创建功能
const CanCreateOperationContext = React.createContext(true);

// 是否是adxBD角色
const IsAdxBDContext = React.createContext(true);

const useAdminUser = () => React.useContext(AdminUserContext);

const useCanCreateOperation = () => React.useContext(CanCreateOperationContext);

const useIsAdxBD = () => React.useContext(IsAdxBDContext);

export {
  AdminUserContext,
  useAdminUser,
  CanCreateOperationContext,
  useCanCreateOperation,
  IsAdxBDContext,
  useIsAdxBD,
};
